export default [
  {
    path: "/services",
    page: "index",
    name: "services",
    meta: {
      sortOrder: 4,
      module: "services",
      title: "services",
      drawer: true,
      main: true
    }
  }
];
