






































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomRouteConfig } from "@/router/routes";
import { AppModule } from "@/store/App";

@Component
export default class Drawer extends Vue {
  @Prop(Array) readonly items!: CustomRouteConfig[];

  switchDrawer(bDrawer: boolean) {
    if (bDrawer !== this.drawer) {
      AppModule.switchDrawer();
    }
  }

  get drawer() {
    return AppModule.drawer;
  }
}
