module.exports = {
  absolute: false,
  rootUrl: "https://lbdrywall.app",
  routes: [
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/blog/categories/list",
      name: "blog.categories.list",
      action: "PlanetaDelEsteApiGoodNewsControllersApiCategories@list"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/blog/articles",
      name: "blog.articles.index",
      action: "PlanetaDelEsteApiGoodNewsControllersApiArticles@index"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/blog/articles/{slug}",
      name: "blog.articles.show",
      action: "PlanetaDelEsteApiGoodNewsControllersApiArticles@show"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/blog/categories",
      name: "blog.categories.index",
      action: "PlanetaDelEsteApiGoodNewsControllersApiCategories@index"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/blog/categories/{id}",
      name: "blog.categories.show",
      action: "PlanetaDelEsteApiGoodNewsControllersApiCategories@show"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/categories/list",
      name: "categories.list",
      action: "PlanetaDelEsteApiShopaholicControllersApiCategories@list"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/categories/tree",
      name: "categories.tree",
      action: "PlanetaDelEsteApiShopaholicControllersApiCategories@tree"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/products/list",
      name: "products.list",
      action: "PlanetaDelEsteApiShopaholicControllersApiProducts@list"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/lang/{lang}",
      name: "lang.",
      action: "PlanetaDelEsteApiShopaholicControllersApiLangs@lang"
    },
    {
      host: null,
      methods: ["POST"],
      uri: "api/v1/lang/tr",
      name: "lang.",
      action: "PlanetaDelEsteApiShopaholicControllersApiLangs@missing"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/categories",
      name: "categories.index",
      action: "PlanetaDelEsteApiShopaholicControllersApiCategories@index"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/categories/{id}",
      name: "categories.show",
      action: "PlanetaDelEsteApiShopaholicControllersApiCategories@show"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/products",
      name: "products.index",
      action: "PlanetaDelEsteApiShopaholicControllersApiProducts@index"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/products/{slug}",
      name: "products.show",
      action: "PlanetaDelEsteApiShopaholicControllersApiProducts@show"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/groups",
      name: "groups.index",
      action: "PlanetaDelEsteApiShopaholicControllersApiGroups@index"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/groups/{group}",
      name: "groups.show",
      action: "PlanetaDelEsteApiShopaholicControllersApiGroups@show"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/brands",
      name: "brands.index",
      action: "PlanetaDelEsteApiShopaholicControllersApiBrands@index"
    },
    {
      host: null,
      methods: ["GET", "HEAD"],
      uri: "api/v1/brands/{slug}",
      name: "brands.show",
      action: "PlanetaDelEsteApiShopaholicControllersApiBrands@show"
    },
    {
      host: null,
      methods: ["POST"],
      uri: "api/v1/forms/send",
      name: "forms.records.send",
      action: "PlanetaDelEsteApiMartinFormsControllersApiRecords@send"
    }
  ],
  prefix: ""
};
