













import { Vue, Component } from "vue-property-decorator";
import routes from "@/router/routes";

import AppBar from "@/components/layout/AppBar.vue";
import Drawer from "@/components/layout/Drawer.vue";
import MainFooter from "@/layout/components/Footer.vue";

@Component({
  components: {
    AppBar,
    Drawer,
    MainFooter
  }
})
export default class MainLayout extends Vue {
  get items() {
    return this.$_.chain(routes)
      .filter(route => {
        return this.$_.get(route, "meta.main", false);
      })
      .map(route => {
        const sDefaultName = this.$_.get(route, "meta.mainDefault");
        if (sDefaultName) {
          this.$_.set(route, "name", `${route.name}-${sDefaultName}`);
        }

        this.$_.set(route, "title", this.$_.get(route, "meta.title"));
        return route;
      })
      .value();
  }
}
