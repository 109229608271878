const package = require("../../package.json");

module.exports = {
  production: {
    API_ENDPOINT: "/api/v1",
    BASE_URL: "/",
    VERSION: package.version
  },
  development: {
    API_ENDPOINT: "/api/v1",
    BASE_URL: "/"
  }
};
