import Vue from "vue";
import Vuetify from "vuetify/lib";
import { es } from "vuetify/es5/locale";
import { Ripple } from "vuetify/lib/directives";
import { i18n } from "@/plugins/i18n";
import _ from "lodash";

// import "@/assets/styles/tailwind.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
    t: (key, ...params) => {
      const name = _.chain(key)
        .trim()
        .kebabCase()
        .replace(/-/g, ".")
        .value();
      return _.toString(i18n.t(name, params));
    }
  },
  directives: {
    Ripple
  },
  theme: {
    themes: {
      light: {
        primary: "#3688c2",
        secondary: "#0a314d"
      }
    }
  }
});
