import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false;

import "./registerServiceWorker";
import "@/plugins";
import { i18n } from "@/plugins/i18n";

const laroute = require("./plugins/laroute.js");
import { Base } from "@bit/planetadeleste.shopaholic-mc.base";
Base.$resolve = laroute.route;
// Base.$authModule = AuthModule;
// Base.$flashModule = FlashModule;
// Base.$loadingModule = LoadingModule;
Base.$http = Vue.axios;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
