import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import { i18n, loadLanguageAsync } from "@/plugins/i18n";
import routes from "./routes";
import _ from "lodash";

Vue.use(VueRouter);

const config = require("@/config");
const ENV = process.env.NODE_ENV;
const { BASE_URL } = ENV ? _.get(config, ENV) : "";

export const BASEURL: string = BASE_URL == "/" ? BASE_URL : BASE_URL + "/";

const router = new VueRouter({
  mode: "history",
  base: BASEURL,
  routes
});

// Set translations
router.beforeEach(async (to: Route, from: Route, next: Function) => {
  const lang = to.params.lang || i18n.locale;
  if (lang) {
    await loadLanguageAsync(lang).then(() => next());
  } else {
    next();
  }
});

export default router;
