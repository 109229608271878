import { RouteConfig, RedirectOption, NavigationGuard } from "vue-router";
import {
  PathToRegexpOptions,
  Component,
  RoutePropsFunction
} from "vue-router/types/router";
import _ from "lodash";

export interface CustomRouteConfig {
  page?: string;
  path: string;
  name?: string;
  children?: RouteConfig[];
  redirect?: RedirectOption;
  alias?: string | string[];
  meta?: any;
  beforeEnter?: NavigationGuard;
  caseSensitive?: boolean;
  pathToRegexpOptions?: PathToRegexpOptions;
  component?: Component;
  props?: boolean | Record<string, any> | RoutePropsFunction;
}

const mapRoute = (
  route: CustomRouteConfig,
  sModuleName?: string,
  parentRoute?: CustomRouteConfig
) => {
  const hasChild = _.isArray(route.children);
  // const isMain = _.get(route, "meta.main", false);
  const sModule = _.get(route, "meta.module", sModuleName);
  const sComponentPath = `modules/${sModule}/views`;
  const sView = _.chain(route.page)
    .camelCase()
    .upperFirst()
    .value();

  // Route is child
  if (parentRoute) {
    if (!route.path || _.isEmpty(route.path) || route.path == "") {
      route.path = parentRoute.path;
    }

    // Construct child route name
    const arRouteParts: string[] = [];

    if (parentRoute.name) {
      arRouteParts.push(parentRoute.name);
    } else {
      arRouteParts.push(_.kebabCase(parentRoute.path));
    }

    if (!route.name) {
      route.name = _.kebabCase(route.path);
    }

    arRouteParts.push(route.name);
    route.name = _.join(arRouteParts, "-");
  }

  if (hasChild) {
    route.children = _.map(route.children, r => mapRoute(r, sModule, route));
  }

  route.component = () => import(`@/${sComponentPath}/${sView}.vue`);

  return route;
};

let baseRoutes: CustomRouteConfig[] = [];

const req = require.context("@/modules", true, /router\/index\.ts/);
req.keys().forEach(key => {
  const obModule = req(key);
  baseRoutes = _.concat(baseRoutes, obModule.default);
});
baseRoutes = _.sortBy(baseRoutes, r => {
  return _.get(r, "meta.sortOrder", 99);
});

const routes = _.map(baseRoutes, r => mapRoute(r));

export default routes;
