import store from "@/store";
import {
  Module,
  VuexModule,
  Action,
  getModule,
  Mutation
} from "vuex-module-decorators";
import Categories from "@/collections/Categories";

const obCategories = new Categories();

@Module({ dynamic: true, store, name: "app" })
class App extends VuexModule {
  private _categories: Categories = obCategories;
  private _drawer = false;

  @Mutation
  private SET_DRAWER(drawer: boolean) {
    this._drawer = drawer;
  }

  @Action
  async loadCategories() {
    await this._categories.fetch();
  }

  @Action
  switchDrawer() {
    this.SET_DRAWER(!this._drawer);
  }

  get categoryCollection() {
    return this._categories;
  }

  get categories() {
    return this._categories.models;
  }

  get loading() {
    return this._categories.loading;
  }

  get drawer() {
    return this._drawer;
  }
}

export const AppModule = getModule(App);
