









































import { Vue, Component } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  goToTop() {
    this.$vuetify.goTo(1, { duration: 500 });
  }
}
