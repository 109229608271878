export default [
  {
    path: "/contact",
    page: "index",
    name: "contact",
    meta: {
      sortOrder: 5,
      module: "contact",
      title: "contact",
      drawer: true,
      main: true
    }
  }
];
