const _ = require("lodash");
const config = require("../config/laroute.js");

(function() {
  const buildParams = (prefix, val, top = true) => {
    if (_.isArray(val)) {
      return _.map(val, function(value, key) {
        return buildParams(top ? key : prefix + "[]", value, false);
      }).join("&");
    } else if (_.isObject(val)) {
      return _.map(val, function(value, key) {
        return buildParams(top ? key : prefix + "[" + key + "]", value, false);
      }).join("&");
    } else {
      return encodeURIComponent(prefix) + "=" + encodeURIComponent(val);
    }
  };

  const laroute = function() {
    const routes = {
      absolute: config.absolute,
      rootUrl: config.rootUrl,
      routes: config.routes,
      prefix: config.prefix,

      route: function(name, parameters, route) {
        route = route || this.getByName(name);

        if (!route) {
          return undefined;
        }

        return this.toRoute(route, parameters);
      },

      url: function(url, parameters) {
        parameters = parameters || [];

        const uri = url + "/" + parameters.join("/");

        return this.getCorrectUrl(uri);
      },

      toRoute: function(route, parameters) {
        const uri = this.replaceNamedParameters(route.uri, parameters);
        const bWithParams =
          _.intersection(route.methods, ["GET", "HEAD"]).length > 0;
        const qs = bWithParams ? this.getRouteQueryString(parameters) : "";

        if (this.absolute && this.isOtherHost(route)) {
          return "//" + route.host + "/" + uri + qs;
        }

        return this.getCorrectUrl(uri + qs);
      },

      isOtherHost: function(route) {
        return route.host && route.host != window.location.hostname;
      },

      replaceNamedParameters: function(uri, parameters) {
        uri = uri.replace(/\{(.*?)\??\}/g, function(match, key) {
          if (_.has(parameters, key)) {
            const value = parameters[key];
            delete parameters[key];
            return value;
          } else {
            return match;
          }
        });

        // Strip out any optional parameters that were not given
        uri = uri.replace(/\/\{.*?\?\}/g, "");

        return uri;
      },

      getRouteQueryString: function(parameters) {
        if (_.isEmpty(parameters) || !_.isObject(parameters)) {
          return "";
        }

        const sParams = buildParams("", parameters);
        return "?" + sParams;
      },

      getByName: function(name) {
        for (const key in this.routes) {
          if (_.has(this.routes, key) && this.routes[key].name === name) {
            return this.routes[key];
          }
        }
      },

      getByAction: function(action) {
        for (const key in this.routes) {
          if (_.has(this.routes, key) && this.routes[key].action === action) {
            return this.routes[key];
          }
        }
      },

      getCorrectUrl: function(uri) {
        const url = this.prefix + "/" + uri.replace(/^\/?/, "");

        if (!this.absolute) {
          return url;
        }

        return this.rootUrl.replace("//?$/", "") + url;
      }
    };

    const getLinkAttributes = function(attributes) {
      if (!attributes) {
        return "";
      }

      const attrs = [];
      for (const key in attributes) {
        if (_.has(attributes, key)) {
          attrs.push(key + '="' + attributes[key] + '"');
        }
      }

      return attrs.join(" ");
    };

    const getHtmlLink = function(url, title, attributes) {
      title = title || url;
      attributes = getLinkAttributes(attributes);

      return '<a href="' + url + '" ' + attributes + ">" + title + "</a>";
    };

    return {
      // Generate a url for a given controller action.
      // laroute.action('HomeController@getIndex', [params = {}])
      action: function(name, parameters) {
        parameters = parameters || {};

        return routes.route(name, parameters, routes.getByAction(name));
      },

      // Generate a url for a given named route.
      // laroute.route('routeName', [params = {}])
      route: function(route, parameters) {
        parameters = parameters || {};

        return routes.route(route, parameters);
      },

      // Generate a fully qualified URL to the given path.
      // laroute.route('url', [params = {}])
      url: function(route, parameters) {
        parameters = parameters || {};

        return routes.url(route, parameters);
      },

      // Generate a html link to the given url.
      // laroute.link_to('foo/bar', [title = url], [attributes = {}])
      link_to: function(url, title, attributes) {
        url = this.url(url);

        return getHtmlLink(url, title, attributes);
      },

      // Generate a html link to the given route.
      // laroute.link_to_route('route.name', [title=url], [parameters = {}], [attributes = {}])
      link_to_route: function(route, title, parameters, attributes) {
        const url = this.route(route, parameters);

        return getHtmlLink(url, title, attributes);
      },

      // Generate a html link to the given controller action.
      // laroute.link_to_action('HomeController@getIndex', [title=url], [parameters = {}], [attributes = {}])
      link_to_action: function(action, title, parameters, attributes) {
        const url = this.action(action, parameters);

        return getHtmlLink(url, title, attributes);
      }
    };
  }.call(this);

  /**
   * Expose the class either via AMD, CommonJS or the global object
   */
  if (typeof module === "object" && module.exports) {
    module.exports = laroute;
  } else {
    window.laroute = laroute;
  }
}.call(this));
