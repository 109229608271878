import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import _ from "lodash";

const config = require("@/config");
const ENV = process.env.NODE_ENV;
const { API_ENDPOINT } = ENV ? _.get(config, ENV) : "";

axios.defaults.baseURL = API_ENDPOINT;
_.set(axios.defaults.headers.common, "Access-Control-Allow-Origin", "*");

axios.interceptors.request.use(
  reqConfig => {
    if (!reqConfig || _.isUndefined(reqConfig.url)) {
      return reqConfig;
    }

    // clean the url
    if (_.startsWith(reqConfig.url, API_ENDPOINT)) {
      const url = reqConfig.url.replace(API_ENDPOINT, "");
      _.set(reqConfig, "url", url);
    }

    return reqConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
