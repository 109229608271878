




























































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomRouteConfig } from "@/router/routes";
import SalesForm from "@/modules/contact/components/SalesForm.vue";
import { AppModule } from "@/store/App";

@Component({
  components: {
    SalesForm
  }
})
export default class AppBar extends Vue {
  @Prop(Array) readonly items!: CustomRouteConfig[];

  switchDrawer() {
    AppModule.switchDrawer();
  }

  get maxWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case "lg":
        return "1185px";
      case "xl":
        return "1785px";
      default:
        return "100%";
    }
  }
}
