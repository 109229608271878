






import { Vue, Component } from "vue-property-decorator";
import { LayoutModule } from "@/store/Layout";
import MainLayout from "@/layout/Main.vue";
import InitLayout from "@/layout/Init.vue";

@Component({
  components: {
    MainLayout,
    InitLayout
  }
})
export default class App extends Vue {
  get layout(): string {
    return `${LayoutModule.getLayout}-layout`;
  }
}
