export default [
  {
    path: "/portfolio",
    page: "index",
    name: "portfolio",
    meta: {
      sortOrder: 2,
      module: "portfolio",
      title: "portfolio",
      drawer: true,
      main: true,
      mainDefault: "all"
    },
    children: [
      {
        path: "",
        page: "list",
        name: "all"
      },
      {
        path: ":slug",
        page: "item",
        name: "item"
      }
    ]
  }
];
