import Vue from "vue";
import {
  Swiper,
  Controller,
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
  Thumbs
} from "swiper/core";
import VueAwesomeSwiper from "vue-awesome-swiper";

// If you use Swiper 6.0.0 or higher
import "swiper/swiper-bundle.css";

Swiper.use([EffectFade, Autoplay, Navigation, Pagination, Thumbs, Controller]);
Vue.use(VueAwesomeSwiper);
