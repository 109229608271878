import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import _ from "lodash";

Vue.use(VueI18n);

const loadedLanguages: Array<any> = [];
const loadedMissing: object = {};

export const i18n = new VueI18n({
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  locale: "es",
  fallbackLocale: "en",
  missing: (lang, message) => {
    if (_.isEmpty(message) || isFinite(Number(message)) || _.isNil(message)) {
      return message;
    }

    if (!_.has(loadedMissing, lang)) {
      _.assign(loadedMissing, { [lang]: {} });
    }

    const loadedMsgs = _.get(loadedMissing, lang);
    if (_.get(loadedMsgs, message)) {
      return message;
    } else {
      _.assign(loadedMsgs, { [message]: message });
      _.assign(loadedMissing, { [lang]: loadedMsgs });
    }

    // console.log(`translation missing: locale=${locale}, key=${key}`)
    axios.post("/lang/tr", { message, lang }).then(response => {
      const msg = _.isUndefined(response.data.message)
        ? message
        : response.data.message;

      if (message !== msg && loadedLanguages.includes(lang)) {
        i18n.mergeLocaleMessage(lang, { [message]: msg });
      }

      return msg;
    });
  }
});

const setI18nLanguage = (lang: string) => {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  const html = document.querySelector("html");
  if (html) {
    html.setAttribute("lang", lang);
  }
  return lang;
};

export const loadLanguageAsync = async (lang: string) => {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  return await axios.get(`/lang/${lang}`).then(response => {
    const msgs = response.data;
    loadedLanguages.push(lang);
    i18n.setLocaleMessage(lang, msgs.messages);
    setI18nLanguage(lang);
  });
};
