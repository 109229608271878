
































































import { Vue, Component } from "vue-property-decorator";
import FormRecord from "@/models/FormRecord";

@Component
export default class SalesForm extends Vue {
  obFormRecord: FormRecord = new FormRecord();
  bDialog = false;
  bLoading = false;
  bSent = false;
  bValid = false;

  mounted() {
    this.obFormRecord = new FormRecord();
    this.obFormRecord.on("change", () => {
      this.isValid();
    });
  }

  close() {
    this.bDialog = false;
    this.obFormRecord.clear();
    this.bLoading = false;
    this.bSent = false;
    this.bValid = false;
  }

  async onSubmit() {
    this.bLoading = true;

    const response = await this.obFormRecord
      .send()
      .then(response => response?.getData());

    this.bLoading = false;

    if (response.status) {
      this.bSent = true;
      this.obFormRecord.clear();
    }
  }

  async isValid() {
    this.bValid = await this.obFormRecord.validate().then(result => {
      return this.$_.isEmpty(result);
    });
  }
}
