import Vue from "vue";
import SvgIcon from "@/components/common/SvgIcon.vue";
import _ from "lodash";

Vue.component("svg-icon", SvgIcon);

export const buildParams = (
  prefix: string | number,
  val: any[] | Record<string, any>,
  top = true
): string => {
  if (_.isArray(val)) {
    return _.map(val, function(value, key) {
      return buildParams(top ? key : prefix + "[]", value, false);
    }).join("&");
  } else if (_.isObject(val)) {
    return _.map(val, function(value, key) {
      return buildParams(top ? key : prefix + "[" + key + "]", value, false);
    }).join("&");
  } else {
    return encodeURIComponent(prefix) + "=" + encodeURIComponent(val);
  }
};
