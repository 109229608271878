



















import { AppModule } from "@/store/App";
import { LayoutModule } from "@/store/Layout";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class InitLayout extends Vue {
  loadingTitle: string | null = "Preparando LBDrywall";

  async mounted() {
    await AppModule.loadCategories();
    this.loadingTitle = null;

    LayoutModule.setLayout("main");
  }
}
